import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from "@sentry/react";
// import { Integrations } from "@sentry/tracing";
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  // autoSessionTracking: true,
  // integrations: [
  //   new Integrations.BrowserTracing(),
  // ],

  // // We recommend adjusting this value in production, or using tracesSampler
  // // for finer control
  // tracesSampleRate: 1.0,
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
